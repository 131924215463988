(function ($) {
  Drupal.behaviors.mppCustomProductLayoutV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $mppTabItem = $('.js-mpp-tab-item', context);
      var $mppTabItemTitle = $('.js-tab-title', context);
      var $mppTabContent = $('.js-mpp-tab-content', context);
      var $mppFilter = $('.js-mpp-filter', context);

      $mppTabItem.once().on('click keydown', function (e) {
        var $nextActiveTab = $(this);
        var $activeTab = $mppTabItem.filter('.active');
        var tabName;
        var arrowNav = false;

        if (e.keyCode === 39) {
          $nextActiveTab = $activeTab.next().length > 0 ? $activeTab.next() : $activeTab;
          arrowNav = true;
        } else if (e.keyCode === 37) {
          $nextActiveTab = $activeTab.prev().length > 0 ? $activeTab.prev() : $activeTab;
          arrowNav = true;
        } else if (e.keyCode === 9) {
          $mppTabItemTitle.removeClass('custom-outline');
        }

        if (e.type === 'click' || arrowNav) {
          tabName = $nextActiveTab.data('mpp-tab');
          $mppTabItem.removeClass('active custom-outline');
          $mppTabItemTitle.removeClass('custom-outline');
          $mppTabContent.removeClass('active');
          arrowNav ? $nextActiveTab.find($mppTabItemTitle).addClass('custom-outline') : '';
          $nextActiveTab.addClass('active');
          $mppTabContent.filter('.' + tabName).addClass('active');
          $mppTabItem.attr({
            'aria-selected': 'false',
            'tabindex': '-1'
          });
          $nextActiveTab.attr({
            'aria-selected': 'true',
            'tabindex': '0'
          });
          tabName !== 'product' ? $mppFilter.addClass('hide') : $mppFilter.removeClass('hide');
        }
      });
    },

    attached: false
  };
})(jQuery);
